<template>
  <v-container
    id="devis-detail"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="7"
      >
        <base-material-card
          id="card-devis-detail"
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-3">
              Devis n° {{ devis.numero }} ({{ devis.reference }})
            </div>
          </template>

          <v-card-text class="text-center">
            <v-simple-table
              id="table-devis-detail"
            >
                <tbody>
                  <tr>
                    <th>
                      <v-icon>mdi-traffic-light</v-icon>
                    </th>
                    <th>État</th>
                    <td v-html="displayEtat()"></td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-barcode-scan</v-icon>
                    </th>
                    <th>Référence</th>
                    <td>{{ devis.reference }}</td>
                  </tr>
                  <tr v-if="devis.intitule">
                    <th>
                      <v-icon>mdi-form-textbox</v-icon>
                    </th>
                    <th>Intitulé</th>
                    <td>{{ devis.intitule }}</td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-briefcase-variant</v-icon>
                    </th>
                    <th>Client</th>
                    <td>
                      <router-link :to="{ name: 'ClientDetail', params: { id: devis.clientDestinataire.id } }">
                        {{ devis.clientDestinataire.nomCommercial }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-card-account-mail-outline</v-icon>
                    </th>
                    <th>Contacts commerciaux</th>
                    <td>{{ devis.clientDestinataire.contactsCommercial.map(c => c.prenom + ' ' + c.nom + ' (' + c.email + ')').join(', ') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-account-school</v-icon>
                    </th>
                    <th>Nombre de stagiaires</th>
                    <td>{{ devis.nbStagiaires }}</td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-book-open-page-variant-outline</v-icon>
                    </th>
                    <th>Cours</th>
                    <td>
                      <span v-if="devis.cours">
                        <router-link :to="{ name: 'CoursDetail', params: { id: devis.cours.id }}">{{ devis.cours.name }}</router-link>
                      </span>
                      <span v-else>
                        <i>Aucun cours associé</i>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-flare</v-icon>
                    </th>
                    <th>Type de formation</th>
                    <td>{{ devis.typeFormation }}</td>
                  </tr>
                  <tr v-if="!devis.formationAssociee">
                    <th>
                      <v-icon>mdi-map-marker</v-icon>
                    </th>
                    <th>Lieu proposé</th>
                    <td>{{ devis.adresse ? devis.adresse.ville : 'Visio' }}</td>
                  </tr>
                  <tr v-if="!devis.formationAssociee">
                    <th>
                      <v-icon>mdi-calendar-multiselect</v-icon>
                    </th>
                    <th>Dates proposées</th>
                    <td>
                      <span v-if="devis.dateDebut">
                        Du {{ new Date(devis.dateDebut).toLocaleString('fr-FR').substring(0, 10) }}
                        au {{ new Date(devis.dateFin).toLocaleString('fr-FR').substring(0, 10) }}
                      </span>
                      <span v-else>
                        <i>Aucune date proposée</i>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="devis.formationAssociee">
                    <th>
                      <v-icon>mdi-human-male-board</v-icon>
                    </th>
                    <th>Formation associée</th>
                    <td>
                      <router-link :to="{ name: 'FormationDetail', params: { id: devis.formationAssociee.id } }">
                        {{ devis.formationAssociee.intitule }} ({{ devis.formationAssociee.reference }}) du {{ new Date(devis.formationAssociee.dateDebut).toLocaleString('fr-FR').substring(0, 10) }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-folder-plus-outline</v-icon>
                    </th>
                    <th>Date d'émission</th>
                    <td>{{ new Date(devis.dateEmission).toLocaleString('fr-FR').substring(0, 10) }}</td>
                  </tr>
                  <tr v-if="devis.etat !== 'NOUVEAU'">
                    <th>
                      <v-icon>mdi-send-circle-outline</v-icon>
                    </th>
                    <th>Date d'envoi</th>
                    <td>{{ new Date(devis.dateEnvoi).toLocaleString('fr-FR').substring(0, 10) }}</td>
                  </tr>
                  <tr v-if="devis.etat !== 'NOUVEAU'">
                    <th>
                      <v-icon>mdi-watch-vibrate</v-icon>
                    </th>
                    <th>Date d'échéance</th>
                    <td>{{ new Date(devis.dateEcheance).toLocaleString('fr-FR').substring(0, 10) }}</td>
                  </tr>
                  <tr v-if="devis.etat === 'ACCEPTE'">
                    <th>
                      <v-icon>mdi-check-circle</v-icon>
                    </th>
                    <th>Date d'acceptation</th>
                    <td>{{ new Date(devis.dateAcceptation).toLocaleString('fr-FR').substring(0, 10) }}</td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-cash</v-icon>
                    </th>
                    <th>Montant HT</th>
                    <td>{{ toEuro(devis.montantHT) }}</td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-cash</v-icon>
                    </th>
                    <th>Montant TVA</th>
                    <td>{{ toEuro(devis.montantTVA) }}</td>
                  </tr>
                  <tr>
                    <th>
                      <v-icon>mdi-cash-multiple</v-icon>
                    </th>
                    <th>Montant TTC</th>
                    <td>{{ toEuro(devis.montantTotal) }}</td>
                  </tr>
                  <tr
                    v-for="[title, value] of Object.entries(devis.informationsAdditionnelles)"
                    :key="title"
                  >
                    <th>
                      <v-icon>mdi-information-variant-circle</v-icon>
                    </th>
                    <th>{{ title }}</th>
                    <td>{{ value }}</td>
                  </tr>
                </tbody>
            </v-simple-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="5"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Actions
            </div>
          </template>
          <v-card-text class="text-center">
            <v-btn
              v-if="devis.etat === 'NOUVEAU'"
              color="primary"
              rounded
              class="ma-2"
              @click="$refs.dialogNewEmailRef.show('DEVIS', { devis: devis })"
              :disabled="true"
            >
              <v-icon left>
                mdi-send
              </v-icon>
              Envoyer
            </v-btn>
            <v-btn
              v-if="devis.etat === 'NOUVEAU'"
              color="#725bcf"
              rounded
              class="ma-2"
              @click="mailEnvoye()"
            >
              <v-icon left>
                mdi-send-check
              </v-icon>
              Marquer comme envoyé
            </v-btn>
            <v-btn
              v-if="devis.etat === 'ENVOYE'"
              color="green"
              rounded
              class="ma-2"
              @click="accepter()"
            >
              <v-icon left>
                mdi-thumb-up
              </v-icon>
              Accepté
            </v-btn>
            <v-btn
              v-if="devis.etat === 'ENVOYE'"
              color="#993e3c"
              rounded
              class="ma-2"
              @click="refuser()"
            >
              <v-icon left>
                mdi-thumb-down
              </v-icon>
              Refusé
            </v-btn>
            <v-btn
              v-if="devis.etat === 'NOUVEAU'"
              color="blue"
              rounded
              class="ma-2"
              @click="$router.push({ name: 'ModifierDevis' })"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Modifier
            </v-btn>
            <v-btn
              color="#00838f"
              rounded
              class="ma-2"
              @click="download()"
              :disabled="true"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Télécharger
            </v-btn>
            <v-btn
              v-if="devis.etat === 'ACCEPTE' && !devis.formationAssociee && !devis.lieAFormation"
              color="primary"
              class="ma-2"
              @click="createFormation()"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              Planifier une session de formation
            </v-btn>
            <v-btn
              v-if="devis.etat === 'ACCEPTE' && devis.formationAssociee && !devis.lieAFormation"
              color="primary"
              class="ma-2"
              @click="addClientToFormation()"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              Ajouter à la session de formation
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <dialog-new-email
      ref="dialogNewEmailRef"
      @mailEnvoiEnCours="envoyerDisabled = true"
      @mailEnvoye="mailEnvoye()"
    />
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {

    components: {
      DialogNewEmail: () => import('../../component/DialogNewEmail'),
    },

    data() {
      return {
        items: [],
        devis: {
          clientDestinataire: {
            contactsCommercial: [],
          },
          cours: {},
          formationAssociee: {},
          informationsAdditionnelles: {},
        },
        envoyerDisabled: false,
        downloadDisabled: false,
      }
    },

    created() {
      this.axios.get('/devis/' + this.$route.params.id)
        .then((res) => {
          this.devis = res.data;
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération du devis', type: 'warn' });
        });
    },

    methods: {

      displayEtat() {
        switch (this.devis.etat) {
          case 'NOUVEAU':
            return '<span id="devis-etat" class="badge-chips badge-gray">EN ATTENTE D\'ENVOI</span>';
          case 'ENVOYE':
            return '<span id="devis-etat" class="badge-chips badge-blue">ENVOYÉ</span>';
          case 'ACCEPTE':
            return '<span id="devis-etat" class="badge-chips badge-green">ACCEPTÉ</span>';
          case 'REFUSE':
            return '<span id="devis-etat" class="badge-chips badge-red">REFUSÉ</span>';
        }
      },

      formatPhone(number) {
        return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
      },

      addClientToFormation() {
        const stagiaire = {
          nom: null,
          prenom: null,
          sexe: null,
          dateNaissance: null,
          adresse: null,
          email: null,
          telephone: null,
          societe: this.devis.clientDestinataire,
          type: 'SALARIE',
          signature: null,
        };

        const stagiaires = [];

        for (let i = 0; i < this.devis.nbStagiaires; i++) {
          stagiaires.push(stagiaire);
        }

        this.devis.lieAFormation = true;

        // Ajotuer budget
        this.axios.patch('/formations/' + this.devis.formationAssociee.id + '/addBudget', this.devis.budget)
          // Ajouter stagiaires
          .then(res => this.axios.patch('/formations/' + this.devis.formationAssociee.id + '/addStagiaires', stagiaires))
          .then(res => this.axios.put('/devis/' + this.devis.id, this.devis))
          .then((res) => {
            this.setSnack({ text: 'La formation a été mise à jour avec les données saisies dans le devis', type: 'success' });
            this.$forceUpdate();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise-à-jour de la formation', type: 'warn' });
          });
      },

      mailEnvoye() {
        this.envoyerDisabled = false;
        this.axios.patch('/devis/' + this.devis.id + '/mailEnvoye')
          .then((res) => {
            this.devis = res.data;
            this.$forceUpdate();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise-à-jour du devis', type: 'warn' });
          });
        this.$forceUpdate();
      },

      accepter() {
        this.axios.patch('/devis/' + this.devis.id + '/accepter')
          .then((res) => {
            this.devis = res.data;
            this.$forceUpdate();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise-à-jour du devis', type: 'warn' });
          });
      },

      refuser() {
        this.axios.patch('/devis/' + this.devis.id + '/refuser')
          .then((res) => {
            this.devis = res.data;
            this.$forceUpdate();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise-à-jour du devis', type: 'warn' });
          });
      },

      createFormation() {
        this.$router.push({ name: 'CreerFormation', params: { devis: this.devis } });
      },

      toEuro(num) {
        if (isNaN(num)) {
          num = 0;
        }
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(num);
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }

</script>

<style scoped>

  #table-devis-detail th, #table-devis-detail td {
    font-size: 1rem;
    text-align: left;
  }

  #table-devis-detail th:first-child {
    width: 1%;
  }

  #table-devis-detail th:nth-child(2) {
    width: 33%;
  }

  #table-devis-detail >>> .badge-chips {
    padding: 6px 12px;
    font-weight: 600;
    border-radius: 4px;
  }

  #table-devis-detail >>> .badge-gray {
    background-color: #a6a8ae;
    color: #fff;
  }

  #table-devis-detail >>> .badge-blue {
    background-color: #47a8d2;
    color: #fff;
  }

  #table-devis-detail >>> .badge-green {
    background-color: #4CAF50;
    color: #fff;
  }

  #table-devis-detail >>> .badge-red {
    background-color: #e93162;
    color: #fff;
  }

  .no-underline {
    text-decoration: none;
  }
</style>
